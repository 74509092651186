.page-slider {
    margin: 0 auto;
    // border-bottom: 5px solid $secondary-color;
    position: relative;
    padding: 137px 0 0 0;

    img {
        display: block;
        width: 100%;
    }

    // &:after{
    //     @include sprite( $arrow-red);
    //     content: '';
    //     display:block;
    //     position: absolute;
    //     bottom: -22px;
    //     left: calc((100vw - #{$global-width}) / 2 + 100px);
    // }

    &.archive {
        &:after {
            display: none;
        }
    }

    @include breakpoint(small){
        padding: 0;
    }

    @include breakpoint(medium){
        padding: 129px 0 0 0;
    }

    @include breakpoint(portrait){
        padding: 60px 0 0 0;
    }
}