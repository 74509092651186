.g {

    &form {
        &_wrapper {
            background: $global-border-color;
            padding: 15px;

            input[type='text'],
            textarea {
                border: 1px solid darken($global-border-color,5%);
                width: calc(100% - 15px);
                font: {
                    size: $global-font-size;
                }
                padding: 5px;
            }

            .gfield_radio {
                label {
                    margin: 0 0 0 5px;
                    a {
                        display: inline-block;
                        text-decoration: underline;

                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
            }

            .validation_message {
                color: #a94442;
            }

            .validation_error {
                padding: 10px;
                background: #f2dede;
                border: 1px solid #ebcccc;
                color:#a94442;
            }

            .gfield_description {
                padding: 10px;
                background: #d9edf7;
                border: 1px solid #bce8f1;
                color:#31708f;

                &.validation_message {
                    background: #f2dede;
                    border: 1px solid #ebcccc;
                    color:#a94442;
                }
            }
        }

        &_button {
            @extend .button__secondary;
            margin: 10px 0 0 0;
        }

        &_body {
            ul {
                margin: 0;
                padding: 0;

                > li {
                    list-style: none;
                }
            }
        }
    }

    &f {

    }
}

// wijzigingen formulier specifiek
#gform {
    &_wrapper {
        // contact formulier
        &_1 {
            .gfield_label {
                display: none !important;
            }
            input, textarea {
                margin: 10px 0;
            }
        }
        // bel mij terug
        &_2 {
            padding: 0px;

            .gfield_label {
                display: none !important;
            }
            input, textarea {
                margin: 10px 0;
            }
        }
        // offerte
        &_3 {

        }
    }
}