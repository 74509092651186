/* Main */
$global-width: 970px;
$global-right:15px;
$global-left: 15px;
$global-padding: 15px;
$global-radius: 0px;
/* Font */
$global-font-size: 17px;
$large-font-size: 22px;
$global-font-family: 'Open Sans', sans-serif;
// $global-font-color: #202020;
$global-font-color: #333333;
/* Colors */
$blauw: #005C86;
$oranje: #F39325;
$primary-color: $blauw;
$secondary-color: $oranje;
$global-border-color: #F5F5F5;

// grid
$gutter: 25px;

// breakpoint mixinis
@mixin breakpoint($point) {
    @if $point == xlarge {
        @media (min-width: 90.063em) { @content ; }
    }
    @else if $point == large {
        @media (min-width: 64.063em) and (max-width: 90em) { @content ; }
    }
    @else if $point == medium {
        @media (min-width: 40.063em) and (max-width: 64em) { @content ; }
    }
    @else if $point == portrait {
        @media (min-width: 40em) and (max-width: 960px) { @content ; }
    }
    @else if $point == small {
        @media (max-width: 40em)  { @content ; }
    }
}
