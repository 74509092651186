// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$chevron-red-name: 'chevron-red';
$chevron-red-x: 90px;
$chevron-red-y: 44px;
$chevron-red-offset-x: -90px;
$chevron-red-offset-y: -44px;
$chevron-red-width: 16px;
$chevron-red-height: 27px;
$chevron-red-total-width: 126px;
$chevron-red-total-height: 85px;
$chevron-red-image: '../img/sprite.png';
$chevron-red: (90px, 44px, -90px, -44px, 16px, 27px, 126px, 85px, '../img/sprite.png', 'chevron-red', );
$chevron-name: 'chevron';
$chevron-x: 110px;
$chevron-y: 0px;
$chevron-offset-x: -110px;
$chevron-offset-y: 0px;
$chevron-width: 16px;
$chevron-height: 27px;
$chevron-total-width: 126px;
$chevron-total-height: 85px;
$chevron-image: '../img/sprite.png';
$chevron: (110px, 0px, -110px, 0px, 16px, 27px, 126px, 85px, '../img/sprite.png', 'chevron', );
$mail-name: 'mail';
$mail-x: 60px;
$mail-y: 45px;
$mail-offset-x: -60px;
$mail-offset-y: -45px;
$mail-width: 27px;
$mail-height: 18px;
$mail-total-width: 126px;
$mail-total-height: 85px;
$mail-image: '../img/sprite.png';
$mail: (60px, 45px, -60px, -45px, 27px, 18px, 126px, 85px, '../img/sprite.png', 'mail', );
$maildark-name: 'maildark';
$maildark-x: 0px;
$maildark-y: 67px;
$maildark-offset-x: 0px;
$maildark-offset-y: -67px;
$maildark-width: 27px;
$maildark-height: 18px;
$maildark-total-width: 126px;
$maildark-total-height: 85px;
$maildark-image: '../img/sprite.png';
$maildark: (0px, 67px, 0px, -67px, 27px, 18px, 126px, 85px, '../img/sprite.png', 'maildark', );
$nav-close-name: 'nav-close';
$nav-close-x: 0px;
$nav-close-y: 45px;
$nav-close-offset-x: 0px;
$nav-close-offset-y: -45px;
$nav-close-width: 30px;
$nav-close-height: 22px;
$nav-close-total-width: 126px;
$nav-close-total-height: 85px;
$nav-close-image: '../img/sprite.png';
$nav-close: (0px, 45px, 0px, -45px, 30px, 22px, 126px, 85px, '../img/sprite.png', 'nav-close', );
$nav-name: 'nav';
$nav-x: 30px;
$nav-y: 45px;
$nav-offset-x: -30px;
$nav-offset-y: -45px;
$nav-width: 30px;
$nav-height: 22px;
$nav-total-width: 126px;
$nav-total-height: 85px;
$nav-image: '../img/sprite.png';
$nav: (30px, 45px, -30px, -45px, 30px, 22px, 126px, 85px, '../img/sprite.png', 'nav', );
$phone-name: 'phone';
$phone-x: 90px;
$phone-y: 0px;
$phone-offset-x: -90px;
$phone-offset-y: 0px;
$phone-width: 20px;
$phone-height: 22px;
$phone-total-width: 126px;
$phone-total-height: 85px;
$phone-image: '../img/sprite.png';
$phone: (90px, 0px, -90px, 0px, 20px, 22px, 126px, 85px, '../img/sprite.png', 'phone', );
$phonedark-name: 'phonedark';
$phonedark-x: 90px;
$phonedark-y: 22px;
$phonedark-offset-x: -90px;
$phonedark-offset-y: -22px;
$phonedark-width: 20px;
$phonedark-height: 22px;
$phonedark-total-width: 126px;
$phonedark-total-height: 85px;
$phonedark-image: '../img/sprite.png';
$phonedark: (90px, 22px, -90px, -22px, 20px, 22px, 126px, 85px, '../img/sprite.png', 'phonedark', );
$share-red-name: 'share-red';
$share-red-x: 0px;
$share-red-y: 0px;
$share-red-offset-x: 0px;
$share-red-offset-y: 0px;
$share-red-width: 45px;
$share-red-height: 45px;
$share-red-total-width: 126px;
$share-red-total-height: 85px;
$share-red-image: '../img/sprite.png';
$share-red: (0px, 0px, 0px, 0px, 45px, 45px, 126px, 85px, '../img/sprite.png', 'share-red', );
$share-name: 'share';
$share-x: 45px;
$share-y: 0px;
$share-offset-x: -45px;
$share-offset-y: 0px;
$share-width: 45px;
$share-height: 45px;
$share-total-width: 126px;
$share-total-height: 85px;
$share-image: '../img/sprite.png';
$share: (45px, 0px, -45px, 0px, 45px, 45px, 126px, 85px, '../img/sprite.png', 'share', );
$spritesheet-width: 126px;
$spritesheet-height: 85px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($chevron-red, $chevron, $mail, $maildark, $nav-close, $nav, $phone, $phonedark, $share-red, $share, );
$spritesheet: (126px, 85px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
