body {
    margin: 0;
    padding: 0;
    // background: $global-border-color;

    font: {
        size: 15px;
        family: $global-font-family;
    }
    line-height: 28px;

    &.hide {
        display: none;
    }
}

.helpers{
    &__left {
        float: left;
    }
    &__right {
        float: right;
    }
}

.wrapper {
    max-width: $global-width;
    margin: 0 auto;
}

.googlemaps {
    min-height: 448px;
}

.c-entry{
    overflow: hidden;
    border: 10px solid $global-border-color;
    padding: 30px;

    img {
        max-width: 100% !important;
    }

    ul {
        margin: 0;
        padding: 0 0 0 20px;

        li {
            list-style: square;
            font: {
                size: 15px;
                family: $global-font-family;
            }
            color: $global-font-color;
            line-height: 28px;
        }
    }

    p {
        font: {
            size: 15px;
            family: $global-font-family;
        }
        color: $global-font-color;
        line-height: 28px;
        margin: 0 0 15px 0;
    }

    a {
        color: $secondary-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }


    @include headings(24px, 2px);

    @include breakpoint(small){
        padding: 10px;
    }
}

