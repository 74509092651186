.page-footer {

    .wrapper {
        text-align: center;
    }

    &__text {
        text-align: center;
        display: block;
        font-size: $global-font-size - 4px;

        a {
            text-decoration: none;
            color: $global-font-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__bottom {
        position: relative;
        margin-top: -20px;

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            right: auto;
            top: 30px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 1px solid transparent;
            border-left: 100vw solid $primary-color;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0px;
            left: auto;
            top: 30px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 1px solid transparent;
            border-right: 100vw solid $secondary-color;
        }
    }

    @include breakpoint(small){
        &__text {
            font-size: $global-font-size - 6px;
        }
    }

}