.widget {
    margin: 0 0 15px 0;
    background: $global-border-color;
    padding: 15px;
    text-align: center;

    h3,
    h4 {
        background: darken($global-border-color,5%);
        padding: 15px;
        margin: -15px;
        display: block;
        color: $global-font-color;
        text-align: left;
        font-size: $global-font-size + 2px;
    }

    ul {
        margin: 0 0 0 5px;
        padding: 10px 25px;

        li {
            list-style: square;
            color: $global-font-color;
            text-align: left;

            a {
                font-size: $global-font-size - 2px;
                padding: 5px 0;
                display: block;
                text-decoration: none;
                color: $global-font-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .textwidget {
        background: $global-border-color;
        position: relative;
        color: $global-font-color;
        text-align: left;

        a {
            color: $global-font-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $secondary-color;
            }
        }

        .home & {
            &::before {
                content: '';
                position: absolute;
                left: -30px;
                top: 30px;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right: 15px solid $global-border-color;
            }
        }
    }

    &__content {
        padding: 15px;
    }

    &__footer{
        padding: 15px;
        background: lighten($secondary-color,5%);
        color: #fff;
        font-size: 14px;
        text-align: center;
    }


    &--list {
        background: $primary-color;
        padding: 0;

        h3,
        h4 {
            background: lighten($primary-color,5%);
            padding: 15px;
            display: block;
            margin: 0;
            color: #fff;
            text-align: left;
        }

        ul {
            li {
                color: #fff;
                a {
                    color: #fff;
                    font-size: $global-font-size - 2px;
                    padding: 5px 0;
                    display: block;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }


    }

    &--form {
        // background: lighten($primary-color,30%);
        background: $global-border-color;
        padding: 0;
        text-align: left;

        h4 {
            background: $primary-color;
            margin: 0;
            color: #fff;
            border-bottom: 5px solid $secondary-color;
        }

        .iphorm-outer {
            background: none !important;
            padding: 10px;
            margin: 0;
        }
        form {
            label {
                color: $global-font-color;

                .iphorm-required {
                    color: $global-font-color !important;
                }
            }

            .iphorm-edit-form-wrap {
                display: none;
            }
        }
    }
}