.page-main {
    padding: 160px 0 25px 0;

    h1 {
        border-bottom: 3px solid $secondary-color;
        display: inline-block;
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
    }

    &.home {
        padding: 218px 0 25px 0;
    }

    @include breakpoint(small){
        padding: 10px 0 25px 0;

        &.home {
            padding: 0px 0 25px 0;
        }
        h1 {
            padding: 15px;
        }
    }

    @include breakpoint(medium){
        padding: 180px 25px 25px 25px;
    }
    @include breakpoint(portrait){
        padding: 0px 25px;

        &.home {
            padding: 25px;
        }
    }

}