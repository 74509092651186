.nav-mobile {
    display: none;

    &.active {
        display: block;
    }

    &__level0 {
        margin: 0 -15px;
        padding: 0 15px;

        > li {
            list-style: none;
            border-top: 1px solid white;

            > a {
                color: white;
                text-decoration: none;
                padding: 8px 20px;
                display: block;
            }

            &.current-menu-item {
                > a {
                    background: $secondary-color;
                }

                &.nav-mobile__has-children {
                    > a {
                        background: none;
                        text-decoration: underline;
                    }
                }
            }


        }
    }

    .sub-menu {
        padding: 0;
        margin: 0;

        > li {


            > a {
                color: white;
                text-decoration: none;
                padding: 5px 20px;
                display: block;

                &:before {
                    content: '\00bb';
                    display: inline-block;
                    margin-right: 10px;
                }
            }

            &.current-menu-item{
                a {
                    background: $secondary-color;
                }
            }
        }
    }
}