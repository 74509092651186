.page-logo {
    display: block;
    text-align: center;

    img {
        transform: scale(0.9);
        left: -15px;
        top: 4px;
        position: relative;
    }

    @include breakpoint(small){
        left: 0;
    }
}