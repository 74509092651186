/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
    padding: 0 !important;

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
        touch-action: manipulation;
        -moz-backface-visibility: hidden; /* fix firefox animation glitch */
    }

    .owl-stage:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        /* fix for flashing background */
        -webkit-transform: translate3d(0px, 0px, 0px);
        border: 10px solid #f5f5f5;
    }

    .owl-wrapper,
    .owl-item{
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
    }

    .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }
    .owl-item img {
        display: block;
        width: 100%;
    }

    .owl-nav.disabled,
    .owl-dots.disabled {
        display: none;
    }

    .owl-dot {
        cursor: pointer;
        cursor: hand;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    button.owl-dot {
        background: none;
        color: inherit;
        border: none;
        padding:0!important;
        font: inherit;
    }

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        opacity: 0;
        display: block;
    }

    &.owl-hidden {
        opacity: 0;
    }

    &.owl-refresh .owl-item {
        visibility: hidden;
    }

    &.owl-drag .owl-item {
        touch-action: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &.owl-grab {
        cursor: move;
        cursor: grab;
    }

    &.owl-rtl {
        direction: rtl;
    }

    &.owl-rtl .owl-item {
        float: right;
    }
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel{
    .animated {
        animation-duration: 1000ms;
        animation-fill-mode: both;
    }
    .owl-animated-in {
        z-index: 0;
    }
    .owl-animated-out {
        z-index: 1;
    }
    .fadeOut {
        animation-name: fadeOut;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
/*
 *  Default theme - Owl Carousel CSS File
 */

$color-base:            #869791 !default;
$color-white:           #FFF !default;
$color-gray:            #D6D6D6 !default;

//nav

$nav-color:             $color-white !default;
$nav-color-hover:       $color-white !default;
$nav-font-size:         14px !default;
$nav-rounded:           3px !default;
$nav-margin:            5px !default;
$nav-padding:           4px 7px !default;
$nav-background:        $color-gray !default;
$nav-background-hover:  $color-base !default;
$nav-disabled-opacity:  0.5 !default;

//dots

$dot-width:             10px !default;
$dot-height:            10px !default;
$dot-rounded:           30px !default;
$dot-margin:            5px 7px !default;
$dot-background:        $color-gray !default;
$dot-background-active: $color-base !default;

.owl-theme {
    position: relative;

    // Styling Next and Prev buttons
    .owl-nav {
        position: absolute;
        top: calc(50% - 39px);
        z-index: 8;
        width: 100%;

        .owl-prev,
        .owl-next {
            position:absolute;
            border: none;
            background: $secondary-color;
            color: #fff;
            padding: 0px 10px 5px 10px;
            border-radius: $global-radius;

            span {
                display: block;
                font-size: 40px;
            }
        }
        .owl-prev {
            left: 0px;
        }
        .owl-next {
            right: 0px;
        }

        @include breakpoint(small){
            display: none;
        }
    }

    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: right;
        -webkit-tap-highlight-color: transparent;
        position: relative;
        top: -50px;
        right: 10px;

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                background: $dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: $dot-rounded;
            }

            &.active,
            &:hover {
                span {
                    background: $dot-background-active;
                }
            }
        }
    }
}