.cookie {


    &__container {

        &.show {
            display: block;
        }

        display: none;
        position: fixed;
        bottom: 0px;
        width: 100%;
        background: $primary-color;
        text-align: center;
        padding: 10px 0;
        color: #fff;

        a {
            margin: 0 5px;
        }
    }
}