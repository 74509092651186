.table {
    width: 100%;
    thead {
        tr {
            th {
                padding: 5px;
                text-align: left;
            }
        }
    }
    tbody {
        tr {
            &:nth-child(odd) {
                background: $global-border-color;
            }
            td {
                padding: 5px;
            }
        }
    }

    &--cart {
        tr {
            td {
                &:last-child {
                    text-align: center;
                    a {
                        text-decoration: none !important;
                        color: $global-font-color;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }
            }
        }
    }
}