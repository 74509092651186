.mobile-header {
    display: none;
    background: $primary-color;
    border-bottom: 1px solid white;
    min-height: 50px;

    &__top {
        padding: 5px 0;
        background: url('../img/bg-top.png') #fff no-repeat center center;
        text-align: center;
    }

    &__hamburger {
        display: inline-block;
        padding: 10px 5px 10px 15px;

        &:before {
            @include sprite( $nav );
            content:'';
            display: inline-block;
            position: relative;
            top: 4px;
        }

    }

    &__logo {
        display: inline-block;
        position: relative;
        top: -1px;
        color: white;
        font-weight: bold;

        a {
            color: white;
            text-decoration: none;
        }
    }

    &.active {
        .mobile-header__hamburger {
            &:before {
                @include sprite( $nav-close );
                content:'';
                display: inline-block;
                position: relative;
                top: 4px;
            }
        }
    }

    @include breakpoint(small){
        display: block;
    }

    @include breakpoint(portrait){
        display: block;
    }
}
