.listview {
    margin: 0;
    padding: 25px 0 0 0;

    > li {
        list-style:none;
        display: flex;
        margin: 0 0 15px 0;
    }

    &__text {
        flex: 0 0 calc(65% - 38px);
        max-width: calc(65% - 38px);
        background: #f5f5f5;
        padding: 20px;
        margin: 0;

        h3 {
            font-size: 24px;
        }

        p {
            font: {
                size: 15px;
                family: $global-font-family;
            }
            color: $global-font-color;
            line-height: 28px;
        }

        > a {
            text-decoration: none;
            color: $secondary-color;

            &:hover {
                text-decoration: underline;
            }

            &:after {
                @include sprite( $chevron-red );
                display: inline-block;
                content: '';
                transform: scale(0.5);
                position:relative;
                top: 9px;
                left: 5px;
            }
        }
    }

    &__img {
        flex: 0 0 35%;
        max-width: 35%;
    }

    @include breakpoint(small){
        > li {
            flex-wrap: wrap;
        }

        &__img,
        &__text{
            flex: 0 0 calc(100% - 30px);
            max-width: calc(100% - 30px);
            padding: 5px 0 0 0;
            margin: 0;
        }
    }
}