@mixin headings($h1: 30px, $decreaseBy: 6px) {
   @for $i from 1 through 6 {
      h#{$i} {
         margin: 0 0 10px 0;
         font-size: ($h1+$decreaseBy) - $i * $decreaseBy;
         font-family: $global-font-family;
         font-weight: 400;
         color: darken($global-font-color,20%);
      }
   }
}

@include headings(38px, 6px);

@include breakpoint(small){
    @include headings(28px, 6px);
}