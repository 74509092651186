.gridview {
    @extend .grid;
    margin: 0;
    padding: 0;

    > li {
        @extend .grid__33;
        position: relative;
        list-style: none;
        overflow:hidden;
        margin: 0 0 20px 0;
        flex: 0 0 calc(100% / 3 - 20px);
        max-width: calc(100% / 3 - 20px);

        .gridview--50 & {
            flex: 0 0 calc(100% / 2 - 20px) !important;
            max-width: calc(100% / 2 - 20px) !important;
        }

        a {
            &:hover {
                img {
                    transform: scale(1.2);
                    transition: all .5s ease-in-out;
                }
            }
            img {
                width: 100%;
                transition: all .5s ease-in-out;
                display:block;
                position:relative;
                z-index: 1;
            }
        }

        .price {
            position: absolute;
            background: $secondary-color;
            color: white;
            top: 15px;
            right: 15px;
            z-index: 3;
            padding: 7px 10px;
            border-radius: 3px;
        }
    }

    &__text {
            position: relative;
            display:block;
            padding: 5px 15px;
            min-height: 50px;
            z-index: 4;
            padding: 0 10px;
            text-align: center;
            background: $primary-color;
            border-bottom: 5px solid $secondary-color;

            h3 {
                color: #fff;
                font-size: 20px;
                line-height: 24px;
                padding: 20px 0 15px 0;
            }
    }

    &--50 {
        > li {
            @extend .grid__50;
            position: relative;
            list-style: none;
            overflow:hidden;
            margin: 0 0 20px 0;
            flex: 0 0 calc(100% / 2 - 20px);
            max-width: calc(100% / 2 - 20px);
        }
    }

    &--review {
        > li {

            h3 {
                color: $global-font-color;
                font-size:20px;
            }

            .gridview__text {
                background: $global-border-color;
                text-align: left;
                padding: 35px 15px 15px 15px;
            }

            @include breakpoint(portrait){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &--employee {
        > li {
            padding: 0;
            margin:0 0 35px 0;
            position:relative;
            flex: 0 0 calc(100% / 3 - #{$gutter});
            max-width: calc(100% / 3 - #{$gutter});

            h3 {
                color: $secondary-color;
                font-size:24px;
            }

            img {
                display:block;
            }

            .gridview__text {
                background: $global-border-color;
                text-align: left;
                padding: 10px 15px 15px 15px;
                min-height: 190px;
            }

            @include breakpoint(portrait){
                h3 {
                    font-size: 19px;
                    padding:0;
                }
            }
        }
    }

    @include breakpoint(small){
        flex-wrap: wrap;

        > li {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}