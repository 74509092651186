.breadcrumbs {
    padding: 10px 0;
    margin: 0;
    text-align: right;
    font-size: 12px;

    > li {
        display: inline-block;
        color: $global-font-color;
        font-size: 12px;
        a {
            padding: 0 10px 0 0;
            color: $secondary-color;
            font-size: 12px;
        }

        &:after {
            content: '>';
            color: $global-font-color !important;
            display: inline-block;
            margin: 0 10px 0 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    @include breakpoint(small){
        padding: 0 15px;
    }
}