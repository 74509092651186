.gallery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 !important;

    > li {
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
        list-style: none !important;
        margin: 0 0 30px 0;

        a {
            display: block;

            img {
                width: 100%;
                cursor: zoom-in;
            }
        }
    }
}