.button {
    border-radius: $global-radius;
    color: #fff;
    padding: 7px 15px;
    text-decoration: none;
    border: none;
    display: inline-block;
    font: {
        family: $global-font-family;
        size: $global-font-size + 2px;
    }


    &__primary {
        background-color: $primary-color;

        &:hover {
            background: $secondary-color;
        }
    }

    &__secondary {
        background-color: $secondary-color;

        &:hover {
            background: $primary-color;
        }
    }

    &--accept {
        padding: 4px 10px;
        background-color: $secondary-color;
        &:hover {
            background: darken($secondary-color,15%);
        }
    }

    &--more {
        padding: 4px 10px;
        background-color: $global-border-color;
        color: $global-font-color;
        &:hover {
            background: $global-font-color;
            color: #fff;
        }
    }

    &__sidebar {
        display:block;
        border: 1px solid $secondary-color;
        text-align: center;
        position: relative;
        padding: 12px 15px;
        margin: 0 0 15px 0;
        color: $secondary-color;

        &:hover {
            background: $primary-color;
            color: #fff;
            border: 1px solid $primary-color;
        }

        &.fill {
            background: $secondary-color;
            color: #fff;

            &:hover {
                background: $primary-color;
                color: #fff;
            }
        }
    }
}