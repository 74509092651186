.grid {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    &__end {
        display: flex;
        justify-content: flex-end;
    }

    > div {
        margin: 0 0 30px 0;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__25 {
        flex: 0 0 calc(25% - #{$gutter});
        max-width: calc(25% - #{$gutter});
    }

    &__33 {
        flex: 0 0 calc(100% / 3 - #{$gutter});
        max-width: calc(100% / 3 - #{$gutter});
    }

    &__50 {
        flex: 0 0 calc(50% - #{$gutter});
        max-width: calc(50% - #{$gutter});
    }

    &__66 {
        flex: 0 0 calc(100% / 3 * 2 - #{$gutter});
        max-width: calc(100% / 3 * 2 - #{$gutter});
    }

    &__75 {
        flex: 0 0 calc(75% - #{$gutter});
        max-width: calc(75% - #{$gutter});
    }

    &__100{
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__background {
        background: #eeeeee;

        .c-entry {
            padding: 30px;

            @include breakpoint(medium) {
                padding: 15px;
            }

            @include breakpoint(small) {
                padding: 15px;
            }
        }
    }

    @include breakpoint(small) {
        padding: 10px;
        > div {
            flex: 0 0 100%;
            max-width: 100%;

            // > div {
            //     padding: 15px;
            // }

        }
    }
}