.nav-main {

	&__level0 {
		position: relative;
		text-align: left;
		margin:0;
		padding: 0;

		> li {
			display: inline-block;
			position: relative;

			> a {
				display: block;
				color: white;
				padding: 20px 12px;
				text-decoration: none;
				letter-spacing: 2px;
				font: {
					size: $global-font-size - 2px;
					family: $global-font-family;
					weight: 300;
				}

				@include breakpoint(medium){
					padding: 20px 7px;
				}
			}

			&:hover{
				a {
					background: $secondary-color;
				}
				.sub-menu {
		    		left: 0px;
		    	}
			}

			&.current-menu-item,
			&.current-menu-parent,
			&.current-category-ancestor {
				> a {
					background: $secondary-color;
				}
			}

			// &:last-child {
			// 	a {
			// 		padding-right: 0px;
			// 	}
			// }
		}
	}

	.sub-menu {
		position: absolute;
		top: 68px;
		left: -9000em;
		padding: 0;
		background: $secondary-color;
		z-index: 99;

		> li {
			list-style: none;

			> a {
				display: block;
				padding: 5px 10px;
				color: #fff;
				text-decoration: none;
				text-align: left;
				min-width: 150px;
				font-size: $global-font-size - 4px;

				&:hover {
					color: $primary-color;
				}
			}

			&.current-menu-item {
				> a {
					color: $primary-color;
				}
			}

			&.active {
				> a {
					color: $primary-color;
				}
			}
		}
	}
}