.pagination {
    display: block;
    text-align: left;

    .page-numbers {
        border:1px solid $secondary-color;
        color: $primary-color;
        padding: 5px;
        text-decoration: none;
        display: inline-block;

        &:hover {
            background: $secondary-color;;
            color: white;
        }


        &.current {
            background: $secondary-color;;
            color: white;
        }

        &.next {
        }
    }
}