.home__news{
    position:relative;
    padding: 80px 0 0 0;
    background: white;

    h3 {
        color: $secondary-color;
    }

    a {
        text-decoration: none;
        color: $secondary-color;

        &:hover {
            text-decoration: underline;
        }
    }
    .reviews {
        margin: 0;
        padding: 30px 0 0;
        > li {
            list-style: none;
            background-color: $global-border-color;
            border-bottom: 2px solid $secondary-color;
            padding: 15px;
            margin: 0 0 25px 0;
            position:relative;

            h4{
                margin: 10px 0;
            }

            .price {
                position: absolute;
                top: 15px;
                right: 15px;
                background: $secondary-color;
                color: #fff;
                padding: 5px;
            }
        }
    }

    @include breakpoint(medium){
        padding: 80px 15px 0 15px;
    }

    @include breakpoint(small){
         padding: 80px 0 0 0;
    }
}