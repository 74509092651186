.home__inspiration {
    padding: 80px 0;
    background: $primary-color;
    position: relative;


    h2{
        color: $global-border-color;
        text-align: center;
        font-style: italic;
        font-weight: 300;

        span {
            margin: 0;
            padding: 0;
            font-weight: bold;
        }
    }

    p {
        color: $global-border-color;
        font-size: 17px;
        text-align: center;
        padding: 0 15px
    }

    &__blocks {
        @extend .grid;
        padding: 20px 0 0 0;

        &__item {
            @extend .grid__33;
            position: relative;
            transition: all .2s ease-in-out;

            &:hover {
                transition: all .2s ease-in-out;
                transform: scale(1.03);
            }

            img {
                position: relative;
                z-index: 1;
            }


            p {
                position: absolute;
                bottom: 15px;
                font-size: 20px;
                text-align: left;
                padding:0 50px ;
                z-index: 2;
            }

            &:first-child {
                p {
                    color: $global-font-color;
                }
            }




        }
    }

    @include breakpoint(small){
        &__blocks {
            padding: 0;
            margin: 0;
            flex-wrap: wrap;

            div {
                margin:0;
            }

            &__item {
                flex: 0 0 100%;
                max-width:100%;

                img {
                    max-width: calc(100% - 30px) !important;
                    margin: 15px;
                }

                p {
                    bottom: 45px;
                    font-size: 16px;
                }

                &:before{
                    transform: scale(0.6);
                    top: 30px;
                    right: 30px;
                }
            }
        }
    }

    @include breakpoint(portrait){
        p {
            font-size: 16px;
            padding: 0 25px;
        }
        &__blocks {
            &__item {
                p {
                    padding: 0 20px;
                    font-size: 14px;
                }

                &:before{
                    transform: scale(0.6);
                    top: 5px;
                }
            }
        }
    }

    @include breakpoint(medium){
        &__blocks {
            padding: 0 15px;
            justify-content: space-between;

            &__item {
                display: block;
                flex: 0 0 calc(100% / 3 - 15px);
                max-width: calc(100% / 3 - 15px);

                img {
                    max-width: 100%;
                }
            }
        }
    }
}