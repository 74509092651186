.home__blocks {
    margin-top: -150px;
    padding-bottom: 80px;
    background: $global-border-color;

    ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        li {
            flex: 0 0 calc(30% - 30px);
            max-width: calc(30% - 30px);
            position: relative;
            transition: all .2s ease-in-out;
            list-style: none;

            img {
                position: relative;
                max-width: 100%;
            }

            h2 {
                font-size: 24px;
            }

            p {
                color: $global-border-color;
            }

            a {
                text-indent: -9999em;
                @include sprite( $share );
                content: '';
                display: block;
                position: absolute;
                bottom: 15px;
                right: 15px;

                &:hover {
                    @include sprite( $share-red );
                }
            }

            &:hover {
                transition: all .2s ease-in-out;
                transform: scale(1.03);
            }
        }
    }



    &__text {
        background: $secondary-color;
        color: $global-border-color;
        padding: 30px 30px 5px 30px;
        min-height: 205px;

        h2 {
            color: $global-border-color;
        }
    }

    @include breakpoint(small){
        margin-top: -51px;

        ul {
            flex-wrap: wrap;
            li {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0 0 15px 0;

                img {
                    width: 100%;
                }
            }
        }

        .wrapper {
            padding: 15px;
        }
    }

    @include breakpoint(medium){
        padding: 0 15px 80px 15px;

        ul {
            li {
                flex: 0 0 calc(100% / 3 - 15px);
                max-width: calc(100% / 3 - 15px);
            }
        }
    }

    @include breakpoint(portrait){
        margin-top: -50px;
        &__text {
            h2 {
                font-size: 22px !important;
            }
            padding: 30px 10px 5px 10px;
        }
    }
}
