.page-header {
    position: fixed;
    // will-change: transform;
    transition: transform 400ms linear;
    width: 100%;
    z-index: 99;

    &__top {
        padding: 5px 0;
        background: url('../img/bg-top.png') #fff no-repeat center center;
        text-align: center;
    }

    &__bottom {
        min-height: 68px;
        background: $primary-color;

        .wrapper {
            position: relative;
        }
    }

    &.slideUp {
        position: fixed;
        width: 100%;
        z-index: 9;
        transform: translateY(0%);
    }


    &.slideDown {
        transform: translateY(-100%);
    }


    @include breakpoint(small){
        display: none;
        position: relative;

        &__bottom {
            display: none;
        }

        &__top {
            background: none;
        }

        &__message {
            p {
                padding: 5px 35px 5px 15px;
            }
            .close {
                right: 15px;
            }
        }
    }

    @include breakpoint(portrait){
        display: none;
    }

    @include breakpoint(medium){
        &__top {
            padding: 15px;
        }
        &__bottom {
            .wrapper {
                padding: 0 25px;
            }
        }
    }
}